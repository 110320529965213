<!-- Informe de Atención Personal -->
<template>
  <div class="apds_iap">  

    <!-- Cabecera -->
    <div class="cabecera">
      <base_Header
        v-if="Entorno.header.header"       
        :Entorno="Entorno.header"
        @onEvent="$emit('onEvent', { accion:'cerrar' })">
      </base_Header>
    </div> 

    <v-sheet :elevation="4">

    <div class="contenedor px-2" style="max-width:1150px;"> 
        <div class="columna px-2">                         
            <!-- <img class="img_fondo" src="img/logofondo.png"> -->
            <!-- Cabecera -->
            <div class="row">
                <div class="col-md-2 img_cabecera text-center py-0 px-1">                    
                    <img v-if="logo" :src="require(`@/assets/img/${logo}.png`)" width="100%"/>
                </div>
                <div class="col-md-10 txt_cabecera">
                    <div class="row">
                        <div class="col-md-12 py-0">                                                        
                            INFORME DE ATENCIÓN PERSONAL                                                        
                        </div>
                        <!-- <div class="col-md-2">
                            <v-btn color="secondary" >FIRMA</v-btn>                   
                        </div> -->
                        
                         
                    </div>    
                </div>
            </div>
            <!-- Primera Fila -->
            <div class= "row fila primera_fila">
                <div class="col-md-2 primera_col opcion ">ENTIDAD</div>
                <div class="col-md-10 dato">{{ record.name_callemp }}</div>
            </div>         
            <div class= "row fila">
                <div class="col-md-2 opcion primera_col">EXPEDIENTE</div>               
                <div class="col-md-3 text">REF. <label class="dato pl-1">{{record.callref1}}</label></div>                
                <div class="col-md-4 text">POLIZA Nº <label class="dato pl-1">{{record.poliza}}</label></div>
                <div class="col-md-3 text">CONTROL Nº <label class="dato pl-1">{{record.control}}</label></div>
                
            </div>
            <div class= "row fila">
                <div class="col-md-2 primera_col opcion">SINIESTRO</div>
                <div class="col-md-10 py-0">
                    <div class="row col-md-12 text">APD</div>
                    <div class="row">
                        <div class="col-md-10 dato pt-0">{{ record.name_apd1}}</div>
                        <div class="col-md-2 dato">{{ record.codigo_apd1}}</div>
                    </div>
                </div>
            </div>
            <!-- Fallecido -->
            <div class= "row fila">
                <div class="col-md-2 primera_col opcion">FALLECIDO</div>        
                <div class="col-md-10 py-0"> 
                    <div class="row">
                        <div class="col-md-8 text">Apellidos, Nombre
                            <div class="row">
                                <div class="col-md-12 py-0 dato">{{record.fallename}}</div>
                            </div> 
                        </div>
                        <div class="col-md-2"> 
                            <label class="text">DNI</label>
                            <div class="row"> 
                                <div class="col-md-12 py-0">                
                                    <label class="dato">{{record.falledni}}</label>
                                </div>
                            </div>
                        </div>                        
                        <div class="col-md-2"> 
                            <label class="text">Edad</label>
                            <div class="row">                
                                <div class="col-md-12 py-0"> 
                                    <label class="dato">{{record.falleedad}}</label>               
                                </div>
                            </div> 
                        </div>
                    </div>                
                    <div class="row">
                        <div class="col-md-12 py-0"> 
                            <label class="text">Lugar Fallecimiento</label>
                            <div class="row">
                                <div class="col-md-12 py-0"> 
                                    <label class="dato">{{record.fallelugar}}</label>                        
                                    <div class="row">
                                        <div class="col-md-10 py-0">
                                            <label class="dato2">{{record.name_fallelugarloc}}</label>  
                                        </div>
                                        <div class="col-md-2 text py-0 pr-2">
                                            <input type="checkbox" :checked="record.fallejudicial>'0'" onclick="return false">
                                            <label class="text pl-2">Judicial</label>  
                                        </div>
                                    </div>
                                </div>
                            </div> 
                        </div> 
                    </div>
                </div>
            </div> 
            <!-- Solicitante --> 
            <div class= "row fila">
                <div class="col-md-2 primera_col">                    
                    <label class="opcion">SOLICITANTE</label>
                </div>                
                <div class="col-md-10 py-0"> 
                    <div class="row">
                        <div class="col-md-5"> 
                            <label class="text">Declarante</label>
                            <div class="row">
                                <div class="col-md-12 pt-0 dato">{{record.soliname}}</div>                                    
                            </div> 
                        </div>
                        <div class="col-md-2"> 
                            <label class="text">DNI</label>
                            <div class="row"> 
                                <div class="col-md-12 pt-0 dato">{{record.solidni}}</div>                                    
                            </div>                
                        </div>                        
                        <div class="col-md-2"> 
                            <label class="text">Parentesco</label>
                            <div class="row">                
                                <div class="col-md-12 pt-0"> 
                                    <label class="dato">{{record.soliparen}}</label>               
                                </div>
                            </div> 
                        </div>                        
                        <div class="col-md-3"> 
                            <label class="text">Teléfonos</label>                        
                            <div class="row">                
                                <div class="col-md-12 pt-0 dato">{{record.solitlf1}} - {{record.solitlf2}}</div>                                               
                            </div>                             
                        </div> 
                    </div>                              
                    <div class="row">
                        <div class="col-md-12 pt-0"> 
                            <label class="text py-0">Domicilio</label>
                            <div class="row">
                                <div class="col-md-12 py-0">
                                    <label class="dato">{{record.solidir}} - {{record.solicp}} {{record.solipob}}</label>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-12 py-0"> 
                                    <label class="text">Observaciones</label>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-12 py-0"><label class="dato2">{{record.soliobs}}</label></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>   
            <!-- Tanatorio --> 
            <div class= "row fila">
                <div class="col-md-2 primera_col">                    
                    <label class="opcion" for="tanatorio">TANATORIO</label>          
                </div>
                <div class="col-md-5">
                    <label class="dato">{{record.sepen}}</label><br>
                    <label class="dato2">{{record.name_seploc}}</label>
                </div>                
                <div class="col-md-5 tanatorio">
                    <label class="text" for="lugar">Lugar y dia de Sepelio</label>
                    <div class="row">
                        <div class="col-md-12 py-0"> 
                            <label class="dato">{{record.enterraen}} <br> {{record.enterrafhhr}}</label>
                        </div>
                    </div>
                </div>
            </div>
            <!-- SEPELIO --> 
            <div class= "row fila">
                <div class="col-md-2 primera_col opcion">SEPELIO</div>                   
                <div class="col-md-2">    
                    <div class="row">
                        <div class="col-md-12 py-0">     
                            <input type="checkbox" :checked="record.sepnicho>'0'" onclick="return false">
                            <label class="text pl-2">Nicho</label>                             
                        </div>
                    </div>                
                    <div class="row">
                        <div class="col-md-12 py-0">              
                            <input type="checkbox" :checked="record.sepsepultura>'0'" onclick="return false">
                            <label class="text pl-2">Sepultura</label>                                                         
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-12 py-0">
                            <input type="checkbox" :checked="record.sepcolumbario>'0'" onclick="return false">
                            <label class="text pl-2">Columbario</label>                                          
                        </div>
                    </div>
                </div> 
                <div class="col-md-2">    
                    <div class="row">
                        <div class="col-md-12 py-0">     
                            <input type="checkbox" :checked="record.sepalquiler>'0'" onclick="return false">
                            <label class="text pl-2">Alquiler [{{record.septemporal}} años]</label>                             
                        </div>
                    </div>                
                    <div class="row">
                        <div class="col-md-12 py-0">              
                            <input type="checkbox" :checked="record.sepperpetuidad>'0'" onclick="return false">
                            <label class="text pl-2">Perpetuidad</label>                                                         
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-12 py-0">
                            <input type="checkbox" :checked="record.seppropiedad>'0'" onclick="return false">
                            <label class="text pl-2">Propiedad</label>                                          
                        </div>
                    </div>
                </div> 
                <div class="col-md-2">    
                    <div class="row">
                        <div class="col-md-12 py-0">     
                            <input type="checkbox" :checked="record.lapidas>'0'" onclick="return false">
                            <label class="text pl-2">Lápida</label>                             
                        </div>
                    </div>                
                    <div class="row">
                        <div class="col-md-12 py-0">              
                            <input type="checkbox" :checked="record.sepocupado>'0'" onclick="return false">
                            <label class="text pl-2">Ocupado</label>                                                         
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-12 py-0">
                            <input type="checkbox" :checked="record.sepcustodia>'0'" onclick="return false">
                            <label class="text pl-2">Custodia</label>                                          
                        </div>
                    </div>
                </div>
                <div class="col-md-2">    
                    <div class="row">
                        <div class="col-md-12 py-0">     
                            <input type="checkbox" :checked="record.sepconserva>'0'" onclick="return false">
                            <label class="text pl-2">Conservación</label>                             
                        </div>
                    </div>                
                    <div class="row">
                        <div class="col-md-12 py-0">              
                            <input type="checkbox" :checked="record.sepembalsa>'0'" onclick="return false">
                            <label class="text pl-2">Embalsamamiento</label>                                                         
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-12 py-0">
                            <input type="checkbox" :checked="record.p_incineracion>'0'" onclick="return false">
                            <label class="text pl-2">Incineración</label>                                          
                        </div>
                    </div>
                </div>
            </div>  

            <!-- Traslado --> 
            <div class= "row fila">
                <div class="col-md-2 primera_col opcion">TRASLADO</div>                
                <div class="col-md-2">    
                    <div class="row">
                        <div class="col-md-12 py-0">     
                            <input type="checkbox" :checked="record.p_local>'0'" onclick="return false">
                            <label class="text pl-2">Local</label>                             
                        </div>
                    </div>                
                    <div class="row">
                        <div class="col-md-12 py-0">              
                            <input type="checkbox" :checked="record.p_provincial>'0'" onclick="return false">
                            <label class="text pl-2">Provincial</label>                                                         
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-12 py-0">
                            <input type="checkbox" :checked="record.p_nacional>'0'" onclick="return false">
                            <label class="text pl-2">Nacional</label>                                          
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-12 py-0">
                            <input type="checkbox" :checked="record.p_internacional>'0'" onclick="return false">
                            <label class="text pl-2">Internacional</label>                                          
                        </div>
                    </div>
                </div>                             
                <div class="col-md-8 traslado"> 
                    <div class="row">
                        <div class="col-md-12 text py-0">
                            Salida desde
                            <label class="dato2">{{record.encuentra}}</label><br>
                            LLegada a
                            <label class="dato2">{{record.destino}}</label>            
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-12 text py-0">
                            APD de Destino / Observaciones
                            <label class="dato2">{{record.encuentra}}</label><br>
                            LLegada a
                            <label class="dato2">{{record.name_apd2}}</label>            
                        </div>
                    </div>                          
                </div>  
            </div>

            <!-- Flores --> 
            <div class= "row fila">
                <div class="col-md-2 primera_col opcion">FLORES</div>        
                <div class="col-md-8 text"> Dedicatoria/s			  
                    <div class="row">
                        <!-- <div class="col-md-12 pt-0"><label class="dato">{{ dedicatoria }}</label></div> -->
                        <div class="col-md-12 py-0"><label class="dato">{{ record.recorda1 }}</label>
                            <span v-show="record.recorda2>''" class="pt-0">
                                <br><label class="dato">{{ record.recorda2 }}</label>
                            </span>
                            <span v-show="record.recorda3>''" class="pt-0">
                                <br><label class="dato">{{ record.recorda3 }}</label>
                            </span>
                            <span v-show="record.recorda4>''" class="pt-0">
                                <br><label class="dato">{{ record.recorda4 }}</label>
                            </span>
                        </div>                        
                    </div>
                </div>        
                <div class="col-md-2 centrado">
                    <label class="text">Número</label>
                    <div class="row">
                        <div class="col-md-12">
                        <label class="dato">{{numRecorda}}</label>
                        </div>
                    </div>      
                </div>       
            </div>

            <!-- Esquela --> 
            <div class= "row fila">
                <div class="col-md-2 primera_col opcion">ESQUELA</div>                
                <div class="col-md-10">                    
                    <input type="checkbox" :checked="record.sepesquela>'0'" onclick="return false">                                   
                    <label class="text pl-5">Módulos</label>    
                    <label class="dato">{{ record.sepmodulo }}</label>
                </div>
            </div>

            <!-- Funerarias -->
            <div class= "row fila">
                <div class="col-md-2 primera_col opcion">PROVEEDORES INTERVINIENTES</div>
                <div class="col-md-10">
                    <div class="col-md-12 text px-0 py-0">
                        FUNERARIA<br>
                        <label class="dato">{{record.name_fune1}}</label>
                        <div v-show="record.fune2>0">
                            FUNERARIA Recepción<br>
                            <label class="dato">{{record.name_fune2}}</label><br>
                        </div>                        
                        <div class="row">
                            <div class="col-md-12 text pt-2 py-0">
                                <input type="checkbox" :checked="record.p_adn>'0'" onclick="return false">
                                <label class="text pl-2">ADN</label>
                                <span class="pl-4"><input type="checkbox" :checked="record.p_asisp>'0'" onclick="return false"></span>                                
                                <label class="text pl-2">Asistencia Psicológica</label>
                            </div>
                        </div>
                    </div>                   
                </div>
            </div>

        <!-- Modificaciones Servicios -->
            <div class= "row fila">
                <div class="col-md-2 primera_col opcion">MODIFICACIONES SERVICIOS</div>
                <div class="col-md-10">
                    <p class= "t12">El abajo firmante, como solicitante del servicio, y de acuerdo a los expresos deseos de la familia del fallecido, autoriza a modificar los elementos descritos en la descripción del servicio de las Condiciones Particulares de la póliza en los siguientes términos:</p>                                   
                    <label class="dato">{{record.obs_ser}}</label>
                </div>
            </div>

        <!-- Observaciones -->
            <div class= "row fila">
                <div class="col-md-2 primera_col opcion">OBSERVACIONES</div>
                <div class="col-md-10"><label class="dato2 pb-2">{{record.obs_iap}}</label></div>
            </div>

        <!-- Ley Organica -->
            <div class= "row fila">
                <div class="col-md-2 primera_col opcion">Información Básica sobre Protección de Datos</div>
                <div class="col-md-10 t12" v-if="mapfre">
                    Responsable: MAPFRE ESPAÑA COMPAÑÍA DE SEGUROS Y REASEGUROS, S.A. Finalidades: Gestión del siniestro y contrato de seguro, prevención del fraude gestión integral y centralizada de su relación con el Grupo MAPFRE. Legitimación: Ejecución del Contrato. Destinatarios: Podrán comunicarse datos a terceros y/o realizarse transferencias de datos a terceros países en los términos señalados en la Información Adicional. Derechos: Puede ejercer sus derechos de acceso, rectificación, supresión, limitación, oposición y portabilidad, detallados en la Información Adicional de Protección de Datos. Información Adicional: Puede consultar la Información Adicional de Protección de Datos incluida en http://www.mapfre.es/RGPD#/Docs/ESPESPPERCLIPART02301ESES02.                    
                </div>
                <div class="col-md-10 t12" v-else>                    
                    El solicitante autoriza el tratamiento de los datos personales suministrados voluntariamente en el presente documento para la presentación del
                    servicio solicitado y el posterior envío de un cuestionario de control de calidad del mismo. Todos los datos son tratados con absoluta
                    confidencialidad e integran un fichero bajo la supervisión y control de la entidad señalada en la parte superior de este documento, ane la que el
                    titular de los datos puede ejercitar sus derechos de acceso, rectificación, oposición y cancelación conforme a lo previsto en la Ley Orgánica
                    15/1999 de Protección de Datos de Carácter Personal. Así mismo, acepta que sus datos puedan ser cedidos, exclusivamente para estas
                    finalidades a otras entidades del grupo Funespaña-AFS o a terceros.
                </div>
            </div>
           
            <firma_canvas tip="1" :exp="record.id" :apd="apd" @onEvent="evtFirma" />

        </div>
      </div>
    </v-sheet>
  </div>
</template>



<script>

  import plugs from "@/common/general_plugs";
  const base_Header = () => plugs.groute("base_Header.vue", "base");
  const firma_canvas = () => plugs.groute("firma_canvas.vue", "comp");
      
  export default {    
    components: { base_Header, firma_canvas },
    props: {
      padre: { type:String, default: '' },
      record: { type:Object, default: null},  
      apd: { type:Number, default: 0}, 
      componenteTipo: { type:String, default:'F' },
      Entorno: { type:Object, default: function() { return JSON.parse(JSON.stringify(this.$cfg.base.F)); }}
    },

    data() {
      return {
        schema: {
          ctrls: {}
        },
        api:'',
        stName:'stFapds_iap',        
        btra_permisos:{ extra:{}},

        exp: {
            entidad: 'MAPFRE SEGUROS Y REASEGUROS S.A.',
        },

        dialog:false


        //canvas
        
      };
    },

    computed: {

        dedicatoria() {
            return this.record.recorda1 +
                (this.record.recorda2>''?" - " + this.record.recorda2 : '') +
                (this.record.recorda>''?" - " + this.record.recorda3 : '') +
                (this.record.recorda>''?" - " + this.record.recorda4 : '');                            
        },
        numRecorda() {
            let r=0;
            if (this.record.recorda4>'') r=4; else
            if (this.record.recorda3>'') r=3; else
            if (this.record.recorda2>'') r=2; else
            if (this.record.recorda1>'') r=1;
            return r;
        },

        mapfre() {
            return (this.record.callemp=='83');
        },

        logo() {
            //const url = window.URL.createObjectURL(new Blob(this.item.image.data));

            ////?w= time | bd | tabla | id | tp | idp | 0  nada = imagen, nombre_campo
            //let url="https://iberopa.com/_abk/lib/imagendb.php?w=123|vc_agencias|hoteles_img|||"+this.item.hot; 
            //return url;

            
            let f='';
            //if (this.record.callemp=='82') f='mapfre'; //asistencia
            if (this.record.callemp=='83') f='mapfre';
            if (this.record.callemp=='86') f='bankinter';
            if (this.record.callemp=='53258') f='kutxa';

            return f;


        },
    },

    created() {
        
        this.ini_data();
    },


    methods: {
      ini_data() {        

        // configuro Entorno 
        this.Entorno.header.titulo = "INFORME DE ATENCIÓN PERSONAL"; 

      },


      // obtengo los registros a mostrar
      async ini_component() {                

        /* let args = { tipo:'fnc', accion: 'apds', fn_args: { 
          accion:'liquidaciones',
          fnt:'lista'          
        }};
       
        let apiResult = await this.$store.dispatch("ajaxRequest", { args:args });       
        if (apiResult.sql.error) {
          this.$root.$alert.open(apiResult.sql.msg, 'error');
          return;
        }

        // guardo records del Grid todos los arrays recibidos, en 'recordsDetalle'
        this.$store.commit(this.stName + '/update_records', apiResult.r[0]);
        this.$store.commit(this.stName + '/data2State', { prop:'recordsDetalle', value: apiResult.r });  */
      },


      //
      ver_liquida() {
        if (!this.selected.length) return;
      
        this.dialog= false;
        this.$emit('onEvent', { liq: this.selected[0].liq });
      },


      // eventos de firma canvas
      evtFirma() {
          //alert('ok');
          this.$emit('iapFirma');                

      }

    }
  };
</script>


<style scoped>

/* @font-face {
	font-family: 'Century Gothic';
	src: url('fonts/century_gothic.eot');
	src: url('fonts/century_gothic.eot') format('embedded-opentype'), url('fonts/century_gothic.ttf') format('truetype');
}
*/

body {
	background: #FFFFFF;
	color: #000000;
	font-family:'Century Gothic',Geneva, Arial, Helvetica, sans-serif;
}
p {
	text-align: justify;
	font-size: 13px;
}
li {
	text-align: left;
	font-size: 13px;
}
h4 {
	text-align: left;
	font-size: 15px;
	font-weight: bold;
	text-decoration: underline;
}
h5 {
	text-align: left;
	font-size: 14px;
	font-weight: bold;
	text-decoration: underline;
}
input[type="checkbox"][readonly] {
  pointer-events: none;
}
.container { 
  overflow: hidden;
}
/*.input.checkbox {
  disabled: true;
}*/

.img_fondo {
  position: absolute;
  opacity: 0.3;
  height: 700px;
  top: 22%;
  left: 60%;
}

.t10{ font-size: 11px; }
.t11{ font-size: 11px; }
.t12{ font-size: 12px; }
.t13{ font-size: 14px; }
.t14{ font-size: 14px; }
.t15{ font-size: 15px; }
.t16{ font-size: 16px; }
.t17{ font-size: 17px; }
.t18{ font-size: 18px; }

.verde{ color: #1b6018; }
.negrita{ font-weight: bold; }
.centrado {text-align: center; }

.opcion {
	font-size: 15px;
	font-weight: bold; 
	color: #1b6018;
}
.text {
	font-size: 14px;
	color: #1b6018;
}
.dato {
	font-size: 16px;
	font-weight: bold; 
	color: #000000;
}
.dato2 {
	font-size: 14px;
	font-weight: bold; 
	color: #000000;
}

.img_cabecera {
  margin-top: 1em;
  margin-bottom: 1.5em;
}

.txt_cabecera {
  border: 2px solid #1b6018;
  margin-top: 0.5em;
  text-align: center;
  font-size: 22px;
  font-weight: bold;
  color: #1b6018;    
}
.p1 {
	line-height: 60px;
}

.input { background-color: rgba(0, 0, 0, 0); border:none; }

.fila{
  border: 1px solid #1b6018; 
  border-left: 2px solid #1b6018;
  border-right: 2px solid #1b6018;
}

.primera_col {
  /* padding-bottom: 220px; */
  /* margin-bottom: -220px; */

  border-right: 2px solid #1b6018;
  text-align: center;  
  font-size: 15px;
  color: #1b6018;
}

.primera_fila {
  border-top: 2px solid #1b6018;
}

.ultima_fila {
  border-top: 1px solid #1b6018;
  border-right: 2px solid #1b6018;  
  height: 300px;
}


.tanatorio {
  border-left: 2px solid #1b6018;
}

.traslado {
  height: 100px;
  border-left: 2px solid #1b6018;  
}

.fondo_input {
  background-color: #ffff00;  
  background-color: rgba(255,255,0,0.2);
}
.inputs {
  border: none;
  background: none;
  width: 100%;
}

#cuadro_firma{
	display: none;
    position: absolute;
    top: 90px;
    left: 30px;
    right: 0px;
    bottom: 46px;
    overflow:hidden;
    /*background-color:#ddd;*/
	width: 800px;
	height: 400px;	
	border: 3px solid #1b6018; 
}
#firma{
    cursor:crosshair ;
    background-color:#eee;
}

</style>